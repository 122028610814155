<template>
	<div class="healthEducation">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="活动名称：">
					<el-autocomplete v-model.trim="formInline.name" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView @getList="getList" ref="createView" :isShowDetail="isShowDetail" />
	</div>
</template>

<script>
export default {
	name: 'healthyText',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				name: '',
			},
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '活动名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '活动地点',
					prop: 'address',
					formatter: (row) => {
						return row.address || '-';
					},
				},
				{
					label: '活动形式',
					prop: 'activityForm',
					formatter: (row) => {
						return row.activityForm || '-';
					},
				},
				{
					label: '活动类别',
					prop: 'activityTypeDictionaryItem',
					formatter: (row) => {
						return row.activityTypeDictionaryItem?.title || '-';
					},
				},
				{
					label: '组织者',
					prop: 'organizerName',
					formatter: (row) => {
						return row.organizerName || '-';
					},
				},
				{
					label: '主讲人',
					prop: 'keynoteSpeakerName',
					formatter: (row) => {
						return row.keynoteSpeakerName || '-';
					},
				},
				{
					label: '接收教育人数',
					prop: 'participants',
					formatter: (row) => {
						return row.participants || '-';
					},
				},
				{
					label: '报告人',
					prop: 'reporterName',
					formatter: (row) => {
						return row.reporterName || '-';
					},
				},
				{
					label: '填表人',
					prop: 'preparerName',
					formatter: (row) => {
						return row.preparerName || '-';
					},
				},
				{
					label: '建档时间',
					prop: 'createTime',
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
			],
			isShowDetail: false,
			options: [],
			filterLoading: false,
			link: '',
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get('/management-api/v1/EducationActivities.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							this.link = res.data._links.create.href || '';
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				link: !row ? this.link : row._links.update.href,
				row: row ? row : null,
			};
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>